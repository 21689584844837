// Libs.
import React, { useEffect, useState } from 'react';
// Deps.
import Cart from '../../../components/cart/cart';
import SEO from '../../../components/layout/seo';
import AppLayout from '../../../components/layout/AppLayout';
import NavigationDrawer from '../../../hooks/useNavigationDrawer';
import navigate from '../../../libs/navigate';

const CartPage = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // just for animation.
    setOpen(true);
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('notifychange'));
    }
  }, []);

  const onClose = () => {
    if (typeof window !== 'undefined') {
      navigate('/app/classes/');
    }
  };

  return (
    <AppLayout>
      <SEO title="Cart"/>
      <NavigationDrawer cart={true} drawerStatus={open} header={'All classes'} onClose={onClose}>
        <Cart/>
      </NavigationDrawer>
    </AppLayout>
  );
};

export default CartPage;
